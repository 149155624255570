import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from "react";

// Interfaz actualizada para reflejar las columnas de la tabla
interface TrackingContextProps {
  timeSpent: number; // Tiempo total en segundos
  clicks: number; // Total de clics
  userAgent: string; // Información del navegador y dispositivo
  scrollDepth: number; // Máximo porcentaje de scroll
  location: { ip: string; country: string } | null; // IP y País
  formSubmitted: boolean; // Indica si se envió el formulario
  videoMetrics: {
    playedSeconds: number; // Tiempo total visualizado
    playedPercentage: number; // Máximo porcentaje visualizado
    videoStarted: boolean; // Si el video comenzó
    videoCompleted: boolean; // Si el video fue completado
  };
  actionAprovecharAhora: number; // Clics en "¡Aprovechar Ahora!"
  actionPlanBasico: number; // Clics en el plan básico
  actionPlanAvanzado: number; // Clics en el plan avanzado
  actionPlanPremium: number; // Clics en el plan premium

  // Métodos para actualizar las métricas
  recordClick: () => void;
  setFormSubmitted: () => void;
  updateVideoMetrics: (metrics: Partial<TrackingContextProps["videoMetrics"]>) => void;
  recordActionClick: (action: string) => void; // Registrar clics en acciones
}

export const TrackingContext = createContext<TrackingContextProps | undefined>(
  undefined
);

export const TrackingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [timeSpent, setTimeSpent] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [scrollDepth, setScrollDepth] = useState(0);
  const [location, setLocation] = useState<{ ip: string; country: string } | null>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const startTime = useRef(Date.now());

  // Métricas de video
  const [videoMetrics, setVideoMetrics] = useState({
    playedSeconds: 0,
    playedPercentage: 0,
    videoStarted: false,
    videoCompleted: false,
  });

  // Acciones específicas
  const [actionAprovecharAhora, setActionAprovecharAhora] = useState(0);
  const [actionPlanBasico, setActionPlanBasico] = useState(0);
  const [actionPlanAvanzado, setActionPlanAvanzado] = useState(0);
  const [actionPlanPremium, setActionPlanPremium] = useState(0);

  // Registrar clics totales
  const recordClick = () => setClicks((prev) => prev + 1);

  // Actualizar métricas de video
  const updateVideoMetrics = (metrics: Partial<TrackingContextProps["videoMetrics"]>) => {
    setVideoMetrics((prev) => ({ ...prev, ...metrics }));
  };

  // Registrar clics en acciones específicas
  const recordActionClick = (action: string) => {
    switch (action) {
      case "aprovechar_ahora":
        setActionAprovecharAhora((prev) => prev + 1);
        break;
      case "plan_basico":
        setActionPlanBasico((prev) => prev + 1);
        break;
      case "plan_avanzado":
        setActionPlanAvanzado((prev) => prev + 1);
        break;
      case "plan_premium":
        setActionPlanPremium((prev) => prev + 1);
        break;
      default:
    }
  };

  // Actualizar tiempo transcurrido
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSpent(Math.floor((Date.now() - startTime.current) / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Actualizar profundidad de scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrolled =
        (window.scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
        100;
      const roundedScroll = Math.min(Math.round(scrolled), 100);
      setScrollDepth((prev) => Math.max(prev, roundedScroll));
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Obtener ubicación del usuario
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setLocation({ ip: data.ip, country: data.country_name });
      } catch (error) {
      }
    };

    fetchLocation();
  }, []);

  return (
    <TrackingContext.Provider
      value={{
        timeSpent,
        clicks,
        userAgent: navigator.userAgent,
        scrollDepth,
        location,
        formSubmitted,
        videoMetrics,
        actionAprovecharAhora,
        actionPlanBasico,
        actionPlanAvanzado,
        actionPlanPremium,
        recordClick,
        setFormSubmitted: () => setFormSubmitted(true),
        updateVideoMetrics,
        recordActionClick,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = (): TrackingContextProps => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error("useTracking debe usarse dentro de un TrackingProvider");
  }
  return context;
};
