import React, { useEffect, useRef } from "react";
import { createClient } from "@supabase/supabase-js";
import { useTracking } from "./TrackingContext";

const supabase = createClient(
  "https://kizibrpevvpqqhekmlpo.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtpemlicnBldnZwcXFoZWttbHBvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTU1Mjc1MDYsImV4cCI6MjAzMTEwMzUwNn0.VIuy9N8xaa1ArUDACSWO9fhbKO1Y0VbraHoC70_Dwxo"
);

const MetricsLogger: React.FC = () => {
  const {
    timeSpent,
    clicks,
    userAgent,
    scrollDepth,
    location,
    formSubmitted,
    videoMetrics,
    actionAprovecharAhora,
    actionPlanBasico,
    actionPlanAvanzado,
    actionPlanPremium,
  } = useTracking();

  const lastMetricsRef = useRef({
    timeSpent: 0,
    clicks: 0,
    scrollDepth: 0,
    videoPlayedSeconds: 0,
    videoPlayedPercentage: 0,
    actionAprovecharAhora: 0,
    actionPlanBasico: 0,
    actionPlanAvanzado: 0,
    actionPlanPremium: 0,
  });

  const sendMetricsToSupabase = async () => {
    const sessionId = localStorage.getItem("session_id") || "unknown-session";
    const ip = location?.ip || null;
    const country = location?.country || null;

    if (!ip || !country || ip === "unknown-ip" || country === "unknown-country") {
      return;
    }

    // 1. Obtener métricas existentes desde la base de datos
    try {
      const { data: existingMetrics, error: fetchError } = await supabase
        .from("tracking_metrics")
        .select("*")
        .eq("session_id", sessionId)
        .eq("ip", ip)
        .single();

      if (fetchError && fetchError.code !== "PGRST116") {
        // Ignorar error si no existen registros previos
        return;
      }

      // 2. Sumar la diferencia calculada al valor existente
      const updatedMetrics = {
        session_id: sessionId,
        ip,
        country,
        unique_key: `${sessionId}|${ip}`,
        user_agent: navigator.userAgent,
        time_spent:
          (existingMetrics?.time_spent || 0) + (timeSpent - lastMetricsRef.current.timeSpent),
        clicks: (existingMetrics?.clicks || 0) + (clicks - lastMetricsRef.current.clicks),
        scroll_depth: Math.max(
          existingMetrics?.scroll_depth || 0,
          scrollDepth
        ),
        form_submitted: existingMetrics?.form_submitted || formSubmitted,
        video_played_seconds:
          (existingMetrics?.video_played_seconds || 0) +
          (videoMetrics.playedSeconds - lastMetricsRef.current.videoPlayedSeconds),
        video_played_percentage: Math.max(
          existingMetrics?.video_played_percentage || 0,
          videoMetrics.playedPercentage
        ),
        video_started: existingMetrics?.video_started || videoMetrics.videoStarted,
        video_completed: existingMetrics?.video_completed || videoMetrics.videoCompleted,
        action_aprovechar_ahora:
          (existingMetrics?.action_aprovechar_ahora || 0) +
          (actionAprovecharAhora - lastMetricsRef.current.actionAprovecharAhora),
        action_plan_basico:
          (existingMetrics?.action_plan_basico || 0) +
          (actionPlanBasico - lastMetricsRef.current.actionPlanBasico),
        action_plan_avanzado:
          (existingMetrics?.action_plan_avanzado || 0) +
          (actionPlanAvanzado - lastMetricsRef.current.actionPlanAvanzado),
        action_plan_premium:
          (existingMetrics?.action_plan_premium || 0) +
          (actionPlanPremium - lastMetricsRef.current.actionPlanPremium),
        updated_at: new Date().toISOString(),
      };

      // 3. Insertar o actualizar las métricas en Supabase
      const { error: upsertError } = await supabase
        .from("tracking_metrics")
        .upsert(updatedMetrics, { onConflict: "unique_key" });

      if (upsertError) {
      } else {
        // 4. Actualizar referencias
        lastMetricsRef.current = {
          timeSpent,
          clicks,
          scrollDepth,
          videoPlayedSeconds: videoMetrics.playedSeconds,
          videoPlayedPercentage: videoMetrics.playedPercentage,
          actionAprovecharAhora,
          actionPlanBasico,
          actionPlanAvanzado,
          actionPlanPremium,
        };
      }
    } catch (err) {
    }
  };

  useEffect(() => {
    sendMetricsToSupabase(); // Enviar métricas iniciales

    const interval = setInterval(() => {
      sendMetricsToSupabase();
    }, 30000);

    const handleBeforeUnload = async () => {
      await sendMetricsToSupabase();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      clearInterval(interval);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [
    timeSpent,
    clicks,
    scrollDepth,
    location,
    formSubmitted,
    videoMetrics,
    actionAprovecharAhora,
    actionPlanBasico,
    actionPlanAvanzado,
    actionPlanPremium,
  ]);

  return null; // El componente no renderiza nada visible
};

export default MetricsLogger;
