import React from 'react';
import './testimonials.css'; // Asegúrate de que la ruta coincida con la ubicación real del archivo CSS
import 'react-multi-carousel/lib/styles.css'; // Importar los estilos del carrusel
import Carousel from 'react-multi-carousel';
function StarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      style={{ width: '40px', height: '40px' }} /* Cambia el tamaño */
    >
      <path
        fillRule="evenodd"
        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
        clipRule="evenodd"
      />
    </svg>
  );
}


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Testimonials = () => {
  const feedback = [
    {
      id: "1",
      content: '"Estoy emocionada de compartir que gracias al curso de Fondeate con Éxito, finalmente logré fondear mi cuenta y estoy viendo resultados positivos en mis operaciones. ¡Un curso realmente transformador!"',
      name: "Ana García",
      stars: 5,
    },
    {
      id: "2",
      content: '"La formación me ha hecho pasar mi cuenta de 10k que se me llevaba resistiendo dos meses, gracias Gorka!"',
      name: "Diego Jiménez",
      stars: 5,
    },
    {
      id: "3",
      content: '"No puedo estar más satisfecho con el curso de Fondeate con Éxito. Las lecciones son claras y directas al grano, lo que me permitió avanzar en mi carrera como trader de manera significativa."',
      name: "Juan Martínez",
      stars: 5,
    },
    {
      id: "4",
      content: '"El curso de trading de Fondeate con Éxito ha sido realmente útil para mí. Ahora tengo una mejor comprensión de cómo funciona el mercado."',
      name: "María López",
      stars: 5,
    },
    {
      id: "5",
      content: '"Tomar la decisión de unirme al curso Gorka fue una de las mejores cosas que he hecho. Me dio las herramientas necesarias para entender mejor el mercado y tomar decisiones más informadas."',
      name: "Carlos Rodríguez",
      stars: 5,
    },
    {
      id: "6",
      content: '"Explora el curso de trading de Gorka. Me ayudó a adquirir nuevas habilidades y conocimientos en el mundo del trading. Definitivamente lo recomendaría a otros interesados en el tema."',
      name: "Javier Alonso",
      stars: 5,
    },
    {
      id: "7",
      content: '"El curso de trading de Gorka es una guía completa para aquellos que quieren adentrarse en el mundo del trading. Las lecciones son claras y fáciles de entender, lo que facilitó mi aprendizaje y progreso como trader."',
      name: "Anton Lugo",
      stars: 5,
    },
  ];

  return (
    <div className="testimonials">
      <div className="title-container">
        <span className="title">Alumnos que hablan de <strong>nosotros</strong></span>
      </div>
      <div className="testimonial-container" >
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={2000}
          draggable={true}
          arrows={true}
          className='carrousel'

        >
          {feedback.map((item, index) => (
            <div className="element" key={index}>
              <div className='element-header'>
                <div className="flex flex-row space-x-1 star" >
                  {Array.from({ length: 5 }).map((_, starIndex) =>
                    starIndex < item.stars && <StarIcon key={starIndex} />
                  )}
                </div>
              </div>
              <p>{item.content}</p>
              <p>- {item.name} -</p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;
