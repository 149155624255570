import React from 'react';
import './SectionTitle.css';

interface SectionTitleProps {
    text: string;
    strongText: string;
    className?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ text, strongText, className }) => {
    return (
        <div className={className}>
            <span className="title">
                {text} <strong>{strongText}</strong>
            </span>
        </div>
    );
};

export default SectionTitle;
