import './whatsappbutton.css';
import React from 'react';
import whatsappLogo from './whatsapp-logo.svg';

function WhatsAppButton() {
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

  return (
    <a
      href={`https://wa.me/${whatsappNumber}?text=Cuéntanos%20tu%20consulta%20aquí%20para%20que%20podamos%20ayudarte%20de%20inmediato.`}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-floating-button"
    >
      <img src={whatsappLogo} alt="WhatsApp" />
    </a>
  );
}

export default WhatsAppButton;
