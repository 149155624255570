import React from 'react';
import './footer.css'; // Asegúrate de que la ruta coincida con la ubicación real del archivo CSS
import logo from '../../assets/images/logo.png'
import politicaPrivacidadPDF from '../../assets/pdf/GorkaFX | Politica de Privacidad.pdf';
import youtube from '../../assets/images/youtube.png';
import instagram from '../../assets/images/instragram.png';
import tiktok from '../../assets/images/tiktok.png';
import useIsMobile from '../../hooks/useIsMobile';

const footerData = {
  columns: [
    {
      title: "OFERTA DE PRODUCTO",
      links: ["Plan Básico", "Plan Avanzado", "Plan Premium"],
    },
    {
      title: "EMPRESA",
      links: ["¿Quiénes somos?", "Nuestros servicios", "Planes"],
    },
    {
      title: "CLIENTES",
      links: ["Contacto", "Información legal"],
    },
  ],
  socialMedia: [
    { name: "YouTube", icon: youtube, alt: "YouTube", url: "https://www.youtube.com/channel/UCZLA_cKZbs-M_WW-PQNXW6Q" },
    { name: "Instagram", icon: instagram, alt: "Instagram", url: "https://www.instagram.com/gorkafx/" },
    { name: "Tik Tok", icon: tiktok, alt: "TikTok", url: "https://www.tiktok.com/@gorkafx" },
  ],
  mobile: [{
    links: ["Información legal"],
  }
  ]
};

function Footer() {
  const isMobile = useIsMobile();
  const handleLinkClick = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="Logo" style={{ width: '150px' }} />
          <p>Fondéate con Éxito</p>
        </div>
        {isMobile ?
          <div className="footer-columns">
            {footerData.mobile.map((column, index) => (
              <div key={index} className="footer-column">
                <ul>
                  {column.links.map((link, linkIndex) => {
                    let sectionId = '';
                    let isPDFLink = false;
                    if (link === "Información legal") isPDFLink = true;
                    return (
                      <li key={linkIndex}>
                        {isPDFLink ? (
                          <a href={politicaPrivacidadPDF} target="_blank" rel="noopener noreferrer">{link}</a>
                        ) : (
                          <a href={`#${sectionId}`} onClick={() => handleLinkClick(sectionId)}>{link}</a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
          :
          <div className="footer-columns">
            {footerData.columns.map((column, index) => (
              <div key={index} className="footer-column">
                <h4>{column.title}</h4>
                <ul>
                  {column.links.map((link, linkIndex) => {
                    let sectionId = '';
                    let isPDFLink = false;
                    if (link === "¿Quiénes somos?") sectionId = 'quienes-somos';
                    else if (link === "Nuestros servicios") sectionId = 'servicios';
                    else if (link === "Planes") sectionId = 'planes';
                    else if (link === "Información legal") isPDFLink = true;
                    return (
                      <li key={linkIndex}>
                        {isPDFLink ? (
                          <a href={politicaPrivacidadPDF} target="_blank" rel="noopener noreferrer">{link}</a>
                        ) : (
                          <a href={`#${sectionId}`} onClick={() => handleLinkClick(sectionId)}>{link}</a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        }
      </div>
      <div className="social-media-icons">
        {footerData.socialMedia.map((media, mediaIndex) => (
          <a key={mediaIndex} href={media.url} target="_blank" rel="noopener noreferrer">
            <img src={media.icon} alt={media.alt} />
          </a>
        ))}
      </div>
    </footer>
  );
}

export default Footer;
