import './App.css';

import IntroSection from './sections/IntroSection/IntroSection';
import PlansSection from './sections/PlansSection/PlansSection';
import ServiceSections from './sections/ServicesSection/ServicesSection';
import Testimonials from './sections/Testimonials/Testimonials';
import Suscription from './sections/Suscription/Suscription';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';
import { TrackingProvider } from './context/TrackingContext';
import GlobalClickListener from './components/GlobalClickListener.tsx';
import MetricsLogger from './context/TrackingLogger';



function App() {
  return (
    <TrackingProvider>
      <MetricsLogger />
      <GlobalClickListener>
        <div className="App">
          <Navbar />
          <main>
            <section id="inicio"><IntroSection /></section>
            <section id="servicios"><ServiceSections /></section>
            <section id="planes"><PlansSection /></section>
            <section id="testimonios"><Testimonials /></section>
            <section id="suscription"><Suscription /></section>
          </main>
          <WhatsAppButton />

          <Footer />
        </div>
      </GlobalClickListener>
    </TrackingProvider>
  );
}

export default App;
