import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import './introSection.css';
import play from '../../assets/images/play_8488948.png';
import intro from '../../assets/images/intro.jpeg';
import MainButton from '../../components/MainButton';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import useIsMobile from '../../hooks/useIsMobile';
import { useTracking } from "../../context/TrackingContext";

function IntroSection() {
  const isMobile = useIsMobile();
  const { updateVideoMetrics, recordActionClick } = useTracking();

  const [showVideo, setShowVideo] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0); // Duración del video
  const videoContainerRef = useRef(null);

  const handlePlayVideo = () => {
    setShowVideo(true);
    updateVideoMetrics({ videoStarted: true }); // Registra que el video comenzó
  };

  const handleDuration = (duration: number) => {
    setVideoDuration(duration); // Almacena la duración total del video
  };

  const handleProgress = (progress: { playedSeconds: number; played: number }) => {
    updateVideoMetrics({
      playedSeconds: Math.floor(progress.playedSeconds), // Asegúrate de redondear
      playedPercentage: Math.round(progress.played * 100), // Porcentaje visualizado
    });
  };

  const handleVideoEnd = () => {
    updateVideoMetrics({ videoCompleted: true }); // Marca el video como completado
  };



  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const handleButtonClick = (sectionId: string) => {
    scrollToSection(sectionId);

    // Reinicia el estado de 'focus' del botón para prevenir que se quede "pegado"
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };


  return (
    <section className="intro-section">
      <div className="intro-container">
        <div className="intro-title-container">
          <h1>FONDÉATE CON ÉXITO</h1>
        </div>
      </div>
      {!showVideo && (
        <div className="video-thumbnail" onClick={handlePlayVideo}>
          <img src={intro} alt="Play Video" />
          <button className="play-button">
            <img src={play} alt="Play Video" />
          </button>
        </div>
      )}
      {showVideo && (
        <div ref={videoContainerRef} className="video-player-container">
          <ReactPlayer
            url="hola.mp4" // Cambia esta URL a tu archivo de video
            playing={true}
            controls={true}
            width={isMobile ? "375px" : "800px"}
            height={isMobile ? "210px" : "450px"}
            onPlay={handlePlayVideo} // Detecta cuando el video comienza
            onDuration={handleDuration} // Registra la duración del video
            onProgress={handleProgress} // Captura el progreso del video
            onEnded={handleVideoEnd} // Detecta cuando el video termina
          />
        </div>
      )}


      <div className="subtitle-container">
        <h2 className="subtitulo-destacado">Aprende a tradear y fondear tus cuentas</h2>
        <h2 className="subtitulo">
          Plazas <strong style={{ color: '#2d91d4' }}>CERRADAS</strong> temporalmente
        </h2>
      </div>
      <MainButton onClick={() => handleButtonClick('suscription')}>
        ¡PREINSCRIBIRME!
      </MainButton>
    </section>
  );
}

export default IntroSection;


