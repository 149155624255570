import React from 'react';
import './plansSection.css';
import PlanCard from '../../components/PlanCard/PlanCard';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import useIsMobile from '../../hooks/useIsMobile';
import { useTracking } from '../../context/TrackingContext';

const plans = [
  {
    title: 'BÁSICO',
    features: ['Trading completo de 0 a 100', 'Estrategia exclusiva', 'PDFs exclusivos'],
    notIncluded: ['Gestión de riesgo', 'Psicotrading', 'Plataforma backtesting', 'Acceso a comunidad privada', 'Apoyo las 24 horas', 'Mentoria 1 a 1'],
    button: 'AGOTADO',
    planClass: 'plan-basic',
    offerPrice: '',
    price: '125€',
    url: "https://pay.hotmart.com/T91252374S",
    isDisabled: true, // Habilitado
  },
  {
    title: 'AVANZADO',
    features: ['Trading completo de 0 a 100', 'Estrategia exclusiva', 'PDFs exclusivos', 'Gestión de riesgo', 'Psicotrading', 'Plataforma backtesting'],
    notIncluded: ['Acceso a comunidad privada', 'Apoyo las 24 horas', 'Mentoria 1 a 1'],
    button: 'AGOTADO',
    planClass: 'plan-advanced',
    offerPrice: '',
    price: '250€',
    url: "https://pay.hotmart.com/M91252410N",
    isDisabled: true, // Habilitado
  },
  {
    title: 'PREMIUM',
    features: ['Trading completo de 0 a 100', 'Estrategia exclusiva', 'PDFs exclusivos', 'Gestión de riesgo', 'Psicotrading', 'Plataforma backtesting', 'Acceso a comunidad privada', 'Apoyo las 24 horas', 'Mentoria 1 a 1'],
    notIncluded: [''],
    button: 'AGOTADO',
    planClass: 'plan-premium',
    offerPrice: '',
    price: '440€',
    url: "https://pay.hotmart.com/D88880064L",
    isDisabled: true, // Deshabilitado
  },
];




function PlansSection() {
  const isMobile = useIsMobile();
  const { recordActionClick } = useTracking();

  const handlePlanButtonClick = (planTitle: string) => {
    recordActionClick(`plan_${planTitle.toLowerCase()}`); // Registra el clic en el contexto
  };
  
  return (
    <div className="plans-section">
       <SectionTitle
        text="Planes diseñados para"
        strongText="traders"
        className={isMobile ? "title-container-large" : "title-container"}
      />
      <div className="plans-container">
        {plans.map((plan, index) => (
          <PlanCard key={index} plan={plan} onButtonClick={handlePlanButtonClick} />
        ))}
      </div>
    </div>
  );
}

export default PlansSection;
