import React from 'react';
import MainButton from '../../components/MainButton';
import './PlanCard.css';

interface Plan {
  title: string;
  features: string[];
  notIncluded: string[];
  button: string;
  planClass: string;
  price: string;
  offerPrice?: string; // Nuevo campo opcional
  url: string;
  isDisabled: boolean;
}

interface PlanCardProps {
  plan: Plan;
  onButtonClick: (planTitle: string) => void; // Nueva prop para manejar clics
}

const PlanCard: React.FC<PlanCardProps> = ({ plan, onButtonClick }) => {
  return (
    <div className={`plan ${plan.planClass}`}>
      <div>
        <div className="plan-header">
          <span>{plan.title.toUpperCase()}</span>
          <div className="plan-prices">
            {plan.offerPrice ? (
              <>
                <span className="original-price">{plan.price}</span>
                <span className="offer-price">{plan.offerPrice}</span>
              </>
            ) : (
              <span>{plan.price.toUpperCase()}</span>
            )}
          </div>
        </div>
        <ul>
          {plan.features.map((feature, featureIndex) => (
            <li key={featureIndex}>
              <span className="check-icon">✓</span>{feature}
            </li>
          ))}
          {plan.notIncluded.filter(notIncludedItem => notIncludedItem !== "").map((notIncluded, notIncludedIndex) => (
            <li key={notIncludedIndex}>
              <span className="cross-icon">✕</span>{notIncluded}
            </li>
          ))}
        </ul>
      </div>
      <MainButton
        isDisabled={plan.isDisabled} // Usa la propiedad isDisabled del plan
        onClick={() => {
          if (!plan.isDisabled) {
            onButtonClick(plan.title); // Llama a la función de clic con el título del plan
            window.open(plan.url, "_blank"); // Navega a la URL del plan
          }
        }}
      >
        {plan.button}
      </MainButton>
    </div>
  );
};

export default PlanCard;
