import React, { useState } from 'react';
import './servicesSection.css'; // Asegúrate de que la ruta coincida con la ubicación real del archivo CSS
import estrategia from '../../assets/images/estrategia.png';
import comunidad from '../../assets/images/comunidad.png';
import online from '../../assets/images/online.png'
import fondeado1 from '../../assets/images/fondeado1.png'
import fondeado2 from '../../assets/images/fondeado2.png'
import fondeado3 from '../../assets/images/fondeado3.jpeg'
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import 'react-multi-carousel/lib/styles.css'; // Estilos del carrusel
import Carousel from 'react-multi-carousel';
import ReactModal from 'react-modal';
import useIsMobile from '../../hooks/useIsMobile';

// Datos de los servicios
const services = [
  {
    id: "service-1",
    image: online,
    title: "MENTORÍA PERSONALIZADA",
    description: "Mentoría 1:1 personalizada para resolver todas tus dudas",
  },
  {
    id: "service-2",
    image: estrategia,
    title: "ESTRATEGIA VALIDADA",
    description: "Obtendrás 2 estrategias testeadas y validadas.",
  },

  {
    id: "service-3",
    image: comunidad,
    title: "COMUNIDAD ACTIVA",
    description: "Dispondrás de un grupo privado donde analizamos y operamos el mercado juntos.",
  },

];



const certifications = [
  { id: 'cert-3', image: fondeado3, alt: 'Certificación 3' },
  { id: 'cert-1', image: fondeado1, alt: 'Certificación 1' },
  { id: 'cert-2', image: fondeado2, alt: 'Certificación 2' },
];

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3, partialVisibilityGutter: 20 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 2, partialVisibilityGutter: 20 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 1, partialVisibilityGutter: 20 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1, partialVisibilityGutter: 10 },
};


function CertificationsCarousel() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const isMobile = useIsMobile();

  const openModal = (image: string) => {
    if (!isMobile) {
      setSelectedImage(image);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <section>
      <SectionTitle text="Alumnos Fondeados" strongText="" className="title-container" />
      <div className='text-container'>

        <div style={{ display: "flex", flexDirection: "column", gap: "30px", marginTop: "2rem", justifyContent: "center" }}>
          <div style={{ gap: "20px", padding: "0 0", paddingBottom: "1.5rem" }}>
            <span> Ejemplos de alumnos que han logrado<strong style={{ color: '#2d91d4' }}> fondear</strong>  sus cuentas gracias a nuestras <strong style={{ color: '#2d91d4' }}>estrategias</strong> y <strong style={{ color: '#2d91d4' }}>mentoría</strong>.</span>
            <span> ¡Descubre cómo <strong style={{ color: '#2d91d4' }}>tú </strong>también puedes ser parte de nuestros <strong style={{ color: '#2d91d4' }}>casos de éxito!</strong></span>
          </div>
        </div>

      </div>
      <div className="certifications-carousel">
        <Carousel responsive={responsive} infinite={true} arrows={true}>
          {certifications.map((cert) => (
            <div
              key={cert.id}
              className="certification-item"
              onClick={() => openModal(cert.image)}
              style={{ cursor: 'pointer' }}
            >
              <img src={cert.image} alt={cert.alt} style={{ width: '80%', borderRadius: '10px' }} />
            </div>
          ))}
        </Carousel>
      </div>

      {/* Modal para mostrar la imagen ampliada */}
      {selectedImage && (
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="modal"
          overlayClassName="overlay"
          ariaHideApp={false} // Solo para evitar problemas con accesibilidad en este ejemplo
        >
          <div className="modal-content">
            <img src={selectedImage} alt="Ampliada" style={{ maxWidth: '80%', maxHeight: '80vh' }} />
          </div>
        </ReactModal>
      )}
    </section>
  );
}

const isMobile = window.innerWidth <= 768;
function ServiceSection() {
  return (
    <section className="services-section">
      <SectionTitle
        text="¿Quiénes somos?"
        strongText=""
        className={isMobile ? "title-container-large" : "title-container"}
      />

      <div className='text-container'>
        <span>Somos un equipo de traders con más de <strong style={{ color: '#2d91d4' }}>4 años de experiencia</strong> en los mercados financieros.</span>
        <span>¡Descubre cómo maximizar tus ganancias con nuestras <strong style={{ color: '#2d91d4' }}>estrategias validadas</strong> y asesoramiento experto para que puedas lograr la ansiada <strong style={{ color: '#2d91d4' }}>rentabilidad</strong>!</span>
      </div>
      <div className="services-grid">
        {services.map(service => (
          <div key={service.id} className="service">
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
            <p className="italic">{service.description}</p>
          </div>
        ))}
      </div>

      <CertificationsCarousel />


    </section>
  );
}

export default ServiceSection;
