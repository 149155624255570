import React, { ReactNode, useContext } from "react";
import { TrackingContext } from "../../context/TrackingContext";

const GlobalClickListener: React.FC<{ children: ReactNode }> = ({
    children,
  }) => {
  const tracking = useContext(TrackingContext);

  if (!tracking) return null;

  const handleClick = () => {
    tracking.recordClick();
  };

  return <div onClick={handleClick}>{children}</div>;
};

export default GlobalClickListener;
