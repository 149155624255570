import { createClient } from '@supabase/supabase-js';
import './suscription.css'; // Asegúrate de que la ruta coincida con la ubicación real del archivo CSS
import React, { useState } from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import useIsMobile from '../../hooks/useIsMobile';

const Suscription = () => {
  // Estado para guardar los valores del formulario
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    plan: '' // Valor por defecto
  });
  const Modal = ({ isOpen, close, children }: any) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay" onClick={close}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          {children}
          <button className='suscription-button2' onClick={close}>Cerrar</button>
        </div>
      </div>
    );
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Manejador para actualizar el estado del formulario
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  // Manejador para el envío del formulario
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const postData = {
      name: formData.name,
      email: formData.email,
      plan: formData.plan
    };
    const client = createClient("https://kizibrpevvpqqhekmlpo.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtpemlicnBldnZwcXFoZWttbHBvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTU1Mjc1MDYsImV4cCI6MjAzMTEwMzUwNn0.VIuy9N8xaa1ArUDACSWO9fhbKO1Y0VbraHoC70_Dwxo    ");
    // Inserta los datos en la tabla 'Plans' usando Supabase
    const { data, error } = await client
      .from('Plans')
      .insert([
        {
          email: postData.email,
          name: postData.name,
          plan: postData.plan
        }
      ]);

    if (error) {
      return;
    }

    setIsModalOpen(true);
  };

  const isFormComplete = formData.name && formData.email && formData.plan;
  const isMobile = useIsMobile();

  return (
    <div className="testimonials">
      <SectionTitle
        text="¡Gracias por tu interés en nuestra formación!"
        strongText=""
        className={isMobile ? "title-container-large" : "title-container"}
      />
      <div className='split-container2'>
        <div className="left-content2">
          <div className='text-container2'>
            <p>Actualmente, las plazas están <strong style={{ color: '#2d91d4' }}>agotadas</strong>. Te invitamos a rellenar nuestro <strong style={{ color: '#2d91d4' }}>formulario de preinscripción</strong>. De esta manera, recibirás una notificación tan pronto como se abran nuevas plazas.</p>
          </div>

        </div>
        <div className="right-content2">
          <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
            ¡Gracias por preinscribirte!
            <p> Te avisaremos por correo electrónico cuando se abran las plazas.</p>
          </Modal>
          <div className='formulario'>
            <h3>FORMULARIO DE  <strong>PREINSCRIPCIÓN</strong> </h3>
            <form onSubmit={handleSubmit}>
              <div className='container-input-email'>
                <input
                  className='input-email'
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Correo electrónico*"
                  value={formData.email}
                  onChange={handleChange}
                  required
                /></div>

              <div className='container-input-name'>
                <input
                  className='input-email'
                  aria-invalid='false'
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Nombre*"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />

                <select
                  className='select-plan'
                  id="plan"
                  name="plan"
                  value={formData.plan}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled selected hidden>Plan*</option>
                  <option value="BASICO">Básico</option>
                  <option value="AVANZADO">Avanzado</option>
                  <option value="PREMIUM">Premium</option>

                </select>
              </div>
              <button className="suscription-button2" type="submit" disabled={!isFormComplete}>PREINSCRIBIRME</button>
            </form>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Suscription;
